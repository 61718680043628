// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-available-js": () => import("./../../../src/pages/available.js" /* webpackChunkName: "component---src-pages-available-js" */),
  "component---src-pages-commissions-js": () => import("./../../../src/pages/commissions.js" /* webpackChunkName: "component---src-pages-commissions-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-figurative-js": () => import("./../../../src/pages/figurative.js" /* webpackChunkName: "component---src-pages-figurative-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landscapes-js": () => import("./../../../src/pages/landscapes.js" /* webpackChunkName: "component---src-pages-landscapes-js" */),
  "component---src-pages-stills-js": () => import("./../../../src/pages/stills.js" /* webpackChunkName: "component---src-pages-stills-js" */)
}

